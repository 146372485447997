<template>
  <button @click="btnClicked">
    <div>
      <slot></slot>

    </div>
  </button>
</template>

<script>

export default {
  name: 'WcButton',
  data() {
    return {
    }
  },
  components: {
  },
  props: {
  },
  methods: {
    btnClicked() {
      this.$emit('btnClick');
    },
  }

}
</script>

<style lang="scss" scoped>
button {
  display: block;
  border: none;
  width: 100%;
  border-radius: .5rem;
  background-color: #E3861A;
  color: #fff;
  padding: .5rem 1.5rem;
  >div {
    text-align: center;
  }
}
</style>
